export const Config = {
  Auth: {
    region: "ap-southeast-2",
    userPoolId: "ap-southeast-2_0k54683bg",
    userPoolWebClientId: "u0j028smh58np5vr3b61lmi07",
    oauth: {
      domain: "member-identity.club-journey.com.au",
      redirectSignIn: "https://members.club-journey.com.au/auth/oauth",
      redirectSignOut: "https://members.club-journey.com.au/signin",
      scope: ["email", "openid", "phone", "profile", "aws.cognito.signin.user.admin"],
      responseType: "code",
    },
  },
  AppSync: {
    Member: {
      graphqlEndpoint:  "https://api.member.club-journey.com.au/graphql",
      region: "ap-southeast-2",
    },
    Public: {
      graphqlEndpoint:  "https://api.public.club-journey.com.au/graphql",
      region: "ap-southeast-2",
    },
 }
};
export default Config;
